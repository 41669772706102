import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { columnSize, windowSize } from "../utils/responsive";
import ReactMarkdown from "react-markdown";
import colors from "../utils/colors";

const Section = ({ children, isTextCentered, maxWidth, stripe= false, style }) => (
  <section>
    <SectionContent isTextCentered={isTextCentered} maxWidth={maxWidth} stripe={stripe} style={style}>
      {children}
    </SectionContent>
  </section>
);

Section.propTypes = {
  children: PropTypes.node.isRequired,
  isTextCentered: PropTypes.bool,
  maxWidth: PropTypes.number
};

export default Section;

const SectionContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: ${props => `${props.maxWidth}px` || "100%"};
  background-color: ${props => (props.stripe ? colors.lightBlue : colors.white)};
  ${props => props.isTextCentered && "text-align: center;"};
  padding-top: 30px;
  align-items: center;
  justify-content: center;
}
`;

const SectionHeader = styled.h2`
  font-size: 32px;
  margin-block-start: 0;
  margin-block-end: 1em;
  ${props => props.isTextCentered && "text-align: center;"}
`;

SectionHeader.propTypes = {
  isTextCentered: PropTypes.bool
};

export { SectionHeader };

export const SectionStoreImage = styled.img`
  height: 50px;
  margin: 0 10px;
`;

export const SectionRow = styled.div`
  display: flex;
  flex-direction: row;
  > * {
    margin-right: 40px;
  }
  &:last-child {
    margin-right: 0;
  }

  @media (max-width: ${windowSize.medium}px) {
    flex-direction: column;
    > * {
      margin-right: 0;
    }
  }
`;

export const SectionMarkdown = styled(ReactMarkdown)`
  max-width: ${props => props.maxWidth || columnSize.large}px;
  text-align: left;
  margin: 0 auto;
  padding: 25px;

  img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
  }

  h6 {
    font-size: 12px;
    margin-block-end: 1em;
    margin-block-start: 1em;
    font-weight: normal;
  }
`;
